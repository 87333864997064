import Button from '@components/atoms/button'
import MsgTrans from '@components/common/i18n/message-trans'
import { FastlyImage } from '@components/common/image'
import Tooltip from '@components/common/tooltip'
import type { ProjectType } from '@lib/data/student-projects'
import { t, Trans } from '@lingui/macro'
import { Info } from 'react-feather'

type Props = ProjectType & {
  imagePath: string
}

/** i18n support added */
const ProjectCard = ({
  projectName,
  studentImage,
  grade,
  projectImage,
  projectCategory,
  projectDescription,
  studentName,
  projectLink,
  isAndroidApp,
  imagePath,
}: Props) => {
  return (
    <div className="mx-auto transition duration-500 transform bg-white rounded-xl shadow text-grey hover:shadow-xl hover:scale-105">
      <div className="flex flex-col">
        <div className="pb-2 block">
          <FastlyImage
            src={`${imagePath}${projectImage}.png`}
            height={222}
            width={406}
            objectFit="cover"
            className="rounded-xl rounded-b-none"
            alt={
              typeof projectDescription === 'string'
                ? projectDescription
                : t({ id: projectDescription?.id })
            }
          />
          {studentImage && (
            <div className="-mt-16 ml-5">
              <FastlyImage
                src={`${imagePath}student-images/${studentImage}.png`}
                height={100}
                width={100}
              />
            </div>
          )}
        </div>
        <div className="flex flex-col p-4 pt-0">
          <div className="flex justify-between">
            <span className="text-orange mr-auto text-xl font-600">
              <MsgTrans msg={studentName} />
            </span>
            <span className="text-grey">
              <Trans>Grade</Trans> {grade}
            </span>
          </div>

          <div className="text-grey-800">
            <MsgTrans msg={projectCategory} />
          </div>
          <div className="my-1 flex items-center text-xl font-600">
            <MsgTrans msg={projectName} />{' '}
            <Tooltip
              content={
                <p className="p-2 w-64 leading-tight">
                  <MsgTrans msg={projectDescription} />
                </p>
              }
              showOnHover
            >
              <Info height={16} width={16} className="ml-2 cursor-pointer" />
            </Tooltip>
          </div>
          <a
            href={projectLink}
            target="_blank"
            rel="noreferrer"
            className="mr-auto mt-2"
          >
            {isAndroidApp ? (
              <FastlyImage
                src="/images/icons/google-play-badge.png"
                height={40}
                width={130}
                alt="Google playstore"
              />
            ) : (
              <Button className="w-full">
                <Trans>View project</Trans>
              </Button>
            )}
          </a>
        </div>
      </div>
    </div>
  )
}

export default ProjectCard
