import { CClient } from '@common/utils'
import Button from '@components/atoms/button'
import BookFreeClassBtn from '@components/common/book-free-class-btn'
import { FastlyImage } from '@components/common/image'
import CompetitionCards from '@components/competitions/competition-cards'
import AuthModal from '@containers/competitions/auth-modal.dynamic'
import { useSessionContext } from '@contexts/session'
import useDetectCountry from '@hooks/useDetectCountry'
import { COMPETITION_TYPES } from '@lib/constants/competitions'
import { LinguiJSMessageDescriptor } from '@lib/types/common'
import { formatCountryQuery } from '@lib/utils/common'
import date from '@lib/utils/date.utils'
import { defineMessage, t, Trans } from '@lingui/macro'
import { captureException } from '@sentry/node'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
const Loader = dynamic(() => import('@components/common/loader'))

const IMAGE_PATH = '/images/home/competitions-fold/v1/'

type Institution = {
  name: LinguiJSMessageDescriptor
  image: {
    label: string
    alt: LinguiJSMessageDescriptor
    height?: number
    width?: number
  }
}

const INSTITUTIONS: Institution[] = [
  {
    name: defineMessage({ message: `IISC, Bangalore` }),
    image: {
      label: 'iisc-bangalore',
      alt: defineMessage({ message: `IISC Bangalore` }),
      height: 70,
      width: 85,
    },
  },
  {
    name: defineMessage({ message: `IIT, Guwahati` }),
    image: {
      label: 'iit-ghy',
      alt: defineMessage({ message: `IIT Guwahati` }),
    },
  },
  {
    name: defineMessage({ message: `IIT, Roorkee` }),
    image: {
      label: 'iit-roorkee',
      alt: defineMessage({ message: `IIT Roorkee` }),
    },
  },
  {
    name: defineMessage({ message: `BITS, Pilani` }),
    image: {
      label: 'bits-pilani',
      alt: defineMessage({ message: `BITS Pilani` }),
    },
  },
  {
    name: defineMessage({ message: `Hewlett Packard` }),
    image: {
      label: 'hewlett-packard',
      alt: defineMessage({ message: `Hewlett Packard` }),
      height: 70,
      width: 140,
    },
  },
  {
    name: defineMessage({ message: `Times of India` }),
    image: {
      label: 'times-of-india',
      alt: defineMessage({ message: `Times of India` }),
    },
  },
]

const DEFAULT_HEADING = (
  <>
    <Trans id="homepage.competitionsFold.heading">
      <span className="yellow-underline">Learn</span>,{' '}
      <span className="yellow-underline">compete</span>, and{' '}
      <span className="yellow-underline">win certificates</span> in coding
      competitions
    </Trans>
  </>
)
const DEFAULT_SUBHEADING = (
  <Trans id="homepage.competitionsFold.subHeading">
    Our coding competitions and hackathons are a great way to put your coding
    skills to test and to learn and grow while competing with the best minds
  </Trans>
)

type Props = {
  heading?: React.ReactNode
  subHeading?: React.ReactNode
  localeCountryCode?: string
  shouldShowCTAs?: boolean
  shouldShowPartnerLogos?: boolean
  shouldShowPracticeCompetitions?: boolean
}

/** i18n support added */
const CompetitionsFold = ({
  heading = DEFAULT_HEADING,
  subHeading = DEFAULT_SUBHEADING,
  localeCountryCode,
  shouldShowCTAs = true,
  shouldShowPartnerLogos = false,
  shouldShowPracticeCompetitions = false,
}: Props) => {
  const [liveCompetitions, setLiveCompetitions] = useState([])
  const [practiceCompetitions, setPracticeCompetitions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedSlug, setSelectedSlug] = useState('')

  const router = useRouter()
  const { loggedIn } = useSessionContext()
  const { countryCode: countryCodeBySystem } = useDetectCountry()

  const handleRegisterNow = (slug: string) => {
    if (!loggedIn) {
      setSelectedSlug(slug)
      setIsOpen(true)
      return
    }

    router.push({
      pathname: `/competitions/${slug}/register`,
      query: router.query,
    })
  }

  const countryCode = localeCountryCode || countryCodeBySystem
  const countryQuery = formatCountryQuery(countryCode)

  const fetchLiveCompetitions = async () => {
    const liveCompetitions = []
    const practiceCompetitions = []
    const PAGE_SIZE = 6

    // Determines how many live competitions to show
    const determineLiveCompetitionCount = () => {
      switch (countryCode) {
        case 'US':
          return 1
        default:
          return 2
      }
    }

    try {
      setIsLoading(true)

      const { CODING_CONTEST, HACKATHON } = COMPETITION_TYPES
      const apiEndPoint = `/api/v1/competitions/?page_size=${PAGE_SIZE}&competition_type=${CODING_CONTEST}&competition_type=${HACKATHON}&state=Upcoming&state=Ongoing${countryQuery}`
      const response = await CClient(apiEndPoint)
      const data = await response.json()

      data.results.forEach((competition) => {
        const { start, end } = competition
        // Filters live competitions
        if (date().isBetween(start, end)) {
          competition.isPractice
            ? practiceCompetitions.push(competition)
            : liveCompetitions.push(competition)
        }
      })

      liveCompetitions.sort((competitionA, competitionB) =>
        date(competitionA.end).isBefore(date(competitionB.end)) ? -1 : 0
      )

      const liveCompetitionsCount = determineLiveCompetitionCount()
      setLiveCompetitions(liveCompetitions.slice(0, liveCompetitionsCount))
      setPracticeCompetitions(practiceCompetitions.slice(0, 2))
    } catch (error) {
      captureException(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    countryQuery && fetchLiveCompetitions()
  }, [countryQuery])

  return (
    <div
      id="coding-competitions"
      className="px-5 py-10 lg:py-16 lg:px-0 text-grey lg:container lg:mx-auto text-center"
    >
      <AuthModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        slug={selectedSlug}
        redirectionUrl={`/competitions/${selectedSlug}/register`}
        isGradeFieldShown
        ctaText={t`Register now` as 'Register now'}
      />

      <div className="flex flex-col items-center mx-auto lg:max-w-6xl space-y-8 md:mx-10 lg:mx-auto">
        <div className="mx-0 lg:mx-auto">
          <h2 className="text-2xl leading-tight lg:text-4xl font-600 mb-6">
            {heading}
          </h2>
          <p className="text-xl leading-tight lg:max-w-3xl lg:mx-auto text-grey-800">
            {subHeading}
          </p>
        </div>

        {/** Show loader while fetching */}
        {isLoading && <Loader classes="h-6 w-6 mx-auto my-4" />}

        {/** Live competitions */}
        {liveCompetitions.length > 0 && (
          <div className="w-full">
            <div className="flex justify-content items-center gap-x-2">
              <h2 className="font-700 text-lg">Live</h2>
              <hr className="w-full text-grey-500" />
            </div>
            <CompetitionCards
              cardClasses="shadow hover:shadow-lg transition-all duration-200"
              competitionList={liveCompetitions}
              handleRegisterNow={handleRegisterNow}
            />
          </div>
        )}

        {/** Practice competitions */}
        {shouldShowPracticeCompetitions && practiceCompetitions.length > 0 && (
          <div className="w-full">
            <div className="flex justify-content items-center gap-x-2">
              <h2 className="font-700 text-lg text-grey">Practice</h2>
              <hr className="w-full text-grey-500" />
            </div>
            <CompetitionCards
              competitionList={practiceCompetitions}
              handleRegisterNow={handleRegisterNow}
            />
          </div>
        )}

        {/** Partner logos */}
        {shouldShowPartnerLogos && (
          <div className="grid justify-between w-full grid-cols-2 gap-y-16 lg:gap-y-0 lg:grid-cols-6 lg:gap-x-10 pt-8">
            {INSTITUTIONS.map((institution) => (
              <div
                key={institution.image.label}
                className="flex flex-col space-y-4 text-center"
              >
                <div>
                  <FastlyImage
                    className="mx-auto"
                    src={`${IMAGE_PATH}${institution.image.label}.png`}
                    alt={t({ id: institution.image.alt.id })}
                    height={institution?.image?.height || 70}
                    width={institution?.image?.width || 70}
                  />
                </div>
                <span className="text-lg font-600">
                  <Trans
                    id={institution?.name?.id}
                    values={institution?.name?.values}
                  />
                </span>
              </div>
            ))}
          </div>
        )}

        {/** CTAs */}
        {shouldShowCTAs && (
          <div className="flex w-full flex-col justify-center lg:flex-row gap-4 font-600 pt-5">
            {liveCompetitions.length > 0 && (
              <Link href="/competitions">
                <a>
                  <Button
                    variant="outline"
                    className="w-full lg:w-auto"
                    size="xl"
                  >
                    <Trans>See ongoing competitions</Trans>
                  </Button>
                </a>
              </Link>
            )}
            <BookFreeClassBtn size="xl" />
          </div>
        )}
      </div>
    </div>
  )
}

export default CompetitionsFold
